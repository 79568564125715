@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

ul {
	padding-left: 0 !important;
}

html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

.font-roboto-serif {
	font-family: "Roboto Serif", sans-serif;
}

.vertical-brand-text {
	writing-mode: horizontal-tb;
}

.font-amatic {
	font-family: "Amatic SC", cursive;
}

.font-oswald {
	font-family: "Oswald", sans-serif;
}

@media (min-width: 1024px) {
	.vertical-brand-text {
		writing-mode: vertical-rl;
		text-orientation: mixed;
	}
}

.blocky {
	color: #ffffff;

	text-shadow: 0 1px 0 #cccccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
		0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
		0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
		0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
		0 20px 20px rgba(0, 0, 0, 0.15);
}

.about-container {
	
}

.parallax-container div {
}

.person-card-left {
	top: 20%;
	left: 12%;
}

.person-card-right {
	top: 20%;
	right: 12%;
}
