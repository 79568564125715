.hero-slide {
	min-height: 100vh;
	display: flex;
	position: relative;
	overflow: hidden;
}

.slide-image {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero-slider .content {
	position: relative;
	z-index: 2;
}

.video-names {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
	position: absolute;
	z-index: 99;
	top: 50px;
}

.video-names > button {
}
